<template>
    <div style="height: 100%">
        <div class="d-flex grey lighten-2 align-center px-2" style="position: fixed; top: 48px; left: 64px; height: 32px; width: calc(100% - 64px); z-index: 3">
            {{ longId }}
            <v-spacer />
            <v-btn @click="previewDialogLocal = false" icon><v-icon>highlight_off</v-icon></v-btn>
        </div>
        <div style="margin-top: 32px; height: calc(100% - 32px)" class="hljs mx-10 py-10" v-html="renderedNotebook" v-katex:auto></div>
    </div>
</template>

<script>
import { nb } from '@determined-ai/notebook-render'
import hljs from 'highlight.js'
import { marked } from 'marked'

import 'katex/dist/katex.min.css'

const tokenizer = {
    // Override escaping to exclude \{...\} blocks
    escape(src) {
        const match = src.match(/\\{|\\}/)
        if (match?.index === 0) {
            return {
                type: 'text',
                raw: match[0],
                text: match[0]
            }
        }

        // return false to use original escape tokenizer
        return false
    },
    paragraph(src) {
        const firstMath = src.split('$$\n\n')[0]
        if (src !== firstMath) {
            return {
                type: 'text',
                raw: src,
                text: src
            }
        }
        return false
    }
}

marked.use({
    tokenizer
})

nb.highlighter = (text, pre, code, lang) => {
    const language = lang || 'text'
    const hl = hljs.highlight(text, { language })
    pre.className = 'language-' + language
    if (typeof code !== 'undefined') {
        code.className = 'language-' + language
    }
    return hl.value
}

export default {
    props: ['longId', 'notebookContent', 'nbDialog'],
    data() {
        return {
            previewDialogLocal: this.previewDialog,
            renderedNotebook: ''
        }
    },
    mounted() {
        const parsedNotebook = nb.parse(this.notebookContent)
        this.renderedNotebook = parsedNotebook.render(this.notebookContent).outerHTML
    },
    watch: {
        previewDialogLocal(to, from) {
            this.$emit('update:nbDialog', to)
        },
        nbDialog(to, from) {
            this.previewDialogLocal = to
        }
    }
}
</script>

<style scoped lang="scss">
.hljs {
    font-size: 16px;
    background-color: white;
    ::v-deep {
        h1 {
            margin-bottom: 0.5em;
        }
        .katex {
            font-size: 1.2em;
        }
        .katex-display {
            padding: 1em 0;
        }
        .nb-code-cell {
            padding: 0;
            code[data-language] {
                background-color: transparent;
                padding: 0;
            }
            pre {
                margin: 0;
                padding: 0.5em 1em;
            }
            pre[class*='language-'] {
                overflow: auto;
            }
            .nb-input {
                margin: 1em 0;
                padding: 0;
                background: #eee;
                border: 1px solid #bdbdbd;
            }
            .nb-output {
                margin-top: 0.5em;
                margin-bottom: 1em;
                padding-left: 0.5em;
                border-left: 2px solid #bdbdbd;
            }
        }
        .mord .accent {
            background-color: transparent !important;
        }
    }
}
</style>
